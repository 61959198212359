import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

export default function ForecastPeriodCard({ period, faIcon }) {

  const getTempIcon = (temp) => {
    if (temp < 32) {
      return 'fa-duotone fa-temperature-quarter';
    }
    if (temp < 50) {
      return 'fa-duotone fa-temperature-half';
    }
    if (temp <= 70) {
      return 'fa-duotone fa-temperature-three-quarters';
    }
    if (temp > 70) {
      return 'fa-duotone fa-temperature-full';
    }
  };

  const getTempIconColor = (temp) => {
    if (temp < 45) {
      return "#0000FF";
    }
    if (temp < 65 && temp >= 45) {
      return "";
    }
    if (temp < 85 && temp >= 70) {
      return "#FF5733";
    }
    if (temp >= 85) {
      return "#C70039";
    }
  };

  return (
    <Card elevation={3}>
      <CardHeader title={period.name} color="textSecondary"/>
      <CardContent>
        <Container>
          <Grid container spacing={1} paddingBottom={5}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="body1" fontSize={24}>
                <i className={faIcon} /> {period.shortForecast}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="body1" fontSize={24}>
                <i className={`${getTempIcon(period.temperature)}`} color={getTempIconColor(period.temperature)}/>{" "}
                {period.temperature}&deg;F
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="body1" fontSize={24}>
              <i className="fa-duotone fa-umbrella"></i> {period.probabilityOfPrecipitation}%
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="body1" fontSize={24}>
              <i className="fa-duotone fa-windsock"></i> {period.windSpeedAndDirection}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </CardContent>
    </Card>
  );
}
