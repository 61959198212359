import { gql } from "@apollo/client";

const FORECAST_DAY_PERIOD_FIELDS = `
    name
    isDayTime
    temperature
    probabilityOfPrecipitation
    dewPoint
    relativeHumidity
    windSpeedAndDirection
    shortForecast
    detailedForecast

`;

export const RELATIVE_LOCATION_BY_ZIPCODE = gql
`query relativeLocationForZip($zipCode: String!) {
  currentConditionsForZip(zipCode: $zipCode) {
    relativeLocation {
      properties {
        city
        state
      }
    }
  }
  }
`;

export const FORECAST_DAY_FOR_ZIPCODE = gql`
  query dayForecastForZip($zipCode: String!) {
    dayForecastForZip(zipCode: $zipCode) {
      generatedAt
      periodOne {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodTwo {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      sunrise
      sunset
    }
  }
`;
