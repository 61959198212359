import { useEffect, useState } from "react";
import { useZipCode } from "../shared/context/zipcode-context";
import {
  FORECAST_DAY_FOR_ZIPCODE,
  RELATIVE_LOCATION_BY_ZIPCODE,
} from "./graphql/queries";
import { useQuery } from "@apollo/client";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import ForecastPeriodCard from "./ForecastPeriodCard";
import { Moon } from "lunarphase-js";

export default function ForecastDay() {
  const { state } = useZipCode();
  const [forecastTime, setForecastTime] = useState("");
  const [forecastPeriodOne, setForecastPeriodOne] = useState({});
  const [forecastPeriodTwo, setForecastPeriodTwo] = useState({});
  const [relativeLocation, setRelativeLocation] = useState({});
  const [sunrise, setSunrise] = useState("");
  const [sunset, setSunset] = useState("");
  const [forecastDayLoaded, setForecastDayLoaded] = useState(false);
  const [lunarPhase] = useState(Moon.lunarPhase());
  const [lunarAge] = useState(Moon.lunarAge());
  const [lunarDistance] = useState(Moon.lunarDistance());

  const {
    loading: relativeLocationLoading,
    data: relativeLocationData,
    refetch: relativeLocationRefetch,
  } = useQuery(RELATIVE_LOCATION_BY_ZIPCODE, {
    pollInterval: 3600000,
    variables: {
      zipCode: state.zipCode,
    },
    skip: state.zipCode.length < 5,
  });

  const {
    loading: forecastDayLoading,
    data: forecastDayData,
    refetch: forecastRefetch,
  } = useQuery(FORECAST_DAY_FOR_ZIPCODE, {
    pollInterval:900000,
    variables: {
      zipCode: state.zipCode,
    },
    skip: state.zipCode.length < 5,
  });

  useEffect(() => {
    if (forecastDayData && relativeLocationData) {
      setRelativeLocation(
        relativeLocationData.currentConditionsForZip.relativeLocation
      );
      setForecastTime(forecastDayData.dayForecastForZip.generatedAt);
      setForecastPeriodOne(forecastDayData.dayForecastForZip.periodOne);
      setForecastPeriodTwo(forecastDayData.dayForecastForZip.periodTwo);
      setSunrise(forecastDayData.dayForecastForZip.sunrise);
      setSunset(forecastDayData.dayForecastForZip.sunset);
      setForecastDayLoaded(true);
    }
  }, [
    forecastDayLoading,
    relativeLocationLoading,
    forecastDayData,
    relativeLocationData,
  ]);

  useEffect(() => {
    if (state.zipCode.length === 5) {
      setForecastDayLoaded(false);
      relativeLocationRefetch();
      forecastRefetch();
    }
  }, [state.zipCode, forecastRefetch, relativeLocationRefetch]);

  const getIconForText = (forecastText, isDayTime) => {
    if (
      forecastText.includes("Thunderstorm") ||
      forecastText.includes("Thunder Showers")
    ) {
      return "fa-duotone fa-cloud-bolt";
    }
    if (forecastText.includes("Fog") || forecastText.includes("Mist")) {
      return "fa-duotone fa-cloud-fog";
    }
    if (forecastText.includes("Rain") && forecastText.includes("Snow")) {
      return "fa-duotone fa-snowflake-droplets";
    }
    if (forecastText.includes("Rain")) {
      return "fa-duotone fa-cloud-rain";
    }
    if (forecastText.includes("Drizzle")) {
      return "fa-duotone fa-cloud-drizzle fa-2xl";
    }
    if (
      forecastText.includes("Snow") ||
      forecastText.includes("Snow Showers") ||
      forecastText.includes("Flurries")
    ) {
      return "fa-duotone fa-snowflakes fa-2xl";
    }
    if (
      forecastText.includes("Mostly Sunny") ||
      forecastText.includes("Partly Cloudy")
    ) {
      return !isDayTime
        ? "fa-duotone fa-clouds-moon"
        : "fa-duotone fa-clouds-sun";
    }
    if (forecastText.includes("Sunny") || forecastText.includes("Clear")) {
      return !isDayTime ? "fa-duotone fa-moon-stars" : "fa-duotone fa-sun";
    }
    if (forecastText.includes("Cloudy")) {
      return "fa-duotone fa-clouds";
    }
    if (
      forecastText.includes("Tropical Storm") ||
      forecastText.includes("Hurricane")
    ) {
      return "fa-duotone fa-solid fa-hurricane";
    }
  };

  const getLunarIcon = () => {
    if (lunarPhase === "New") {
      return "fa-solid fa-circle fa-xl";
    }
    if (lunarPhase === "Waxing Crescent") {
      return "fa-duotone fa-moon fa-flip-horizontal fa-xl";
    }
    if (lunarPhase === "First Quarter") {
      return "fa-duotone fa-circle-half-stroke fa-xl";
    }
    if (lunarPhase === "Waxing Gibbous") {
      return "fa-duotone fa-circle-quarter-stroke fa-xl";
    }
    if (lunarPhase === "Full") {
      return "fa-duotone fa-circle fa-xl";
    }
    if (lunarPhase === "Waning Gibbous") {
      return "fa-duotone fa-circle-quarter-stroke fa-flip-horizontal fa-xl";
    }
    if (lunarPhase === "Last Quarter") {
      return "fa-duotone fa-circle-half-stroke fa-flip-horizontal fa-xl";
    }
    if (lunarPhase === "Waning Crescent") {
      return "fa-duotone fa-moon fa-xl";
    }
  };

  return !forecastDayLoaded ? null : (
    <>
      <Container>
        <Grid container spacing={1} paddingBottom={1}>
          <Grid item xs={12} sm={6} md={6}>
            <Card elevation={3}>
              <CardHeader title="Sunrise and Sunset" color="textSecondary" />
              <CardContent>
                <Typography paddingBottom={1} variant="body2" fontSize={18}>
                  <i className="fa-duotone fa-sunrise fa-xl"></i>
                  &nbsp;{`${format(parseFloat(sunrise), "h:mm a")}`}
                </Typography>
                <Typography paddingBottom={1} variant="body2" fontSize={18}>
                  <i className="fa-duotone fa-sunset fa-xl"></i>
                  &nbsp;{`${format(parseFloat(sunset), "h:mm a")}`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Card elevation={3}>
              <CardHeader title="Lunar Tidbits" color="textSecondary" />
              <CardContent>
                <Typography paddingBottom={1} variant="body2" fontSize={18}>
                  <i className={getLunarIcon()}></i> {"  "}
                  {lunarPhase}
                </Typography>
                <Typography paddingBottom={1} variant="body2" fontSize={18}>
                  <i className="fa-duotone fa-timer fa-xl" />
                  {"  "}
                  {lunarAge.toFixed(2)} Earth days old
                </Typography>
                <Typography paddingBottom={1} variant="body2" fontSize={18}>
                  <i className="fa-duotone fa-shuttle-space fa-xl" />
                  {"  "}
                  {(lunarDistance / 2).toFixed(2)} Earths away
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Typography paddingTop={2} paddingBottom={2} variant="h5">
          Short-term Forecast for {relativeLocation.properties.city},{" "}
          {relativeLocation.properties.state}
        </Typography>
        <Typography paddingBottom={2} variant="subtitle2">
          generated at {format(parseISO(forecastTime), "h:mm a")}
        </Typography>
        <Grid container spacing={1} paddingBottom={5}>
          <Grid item xs={12} sm={6} md={6}>
            <ForecastPeriodCard
              faIcon={getIconForText(
                forecastPeriodOne.shortForecast,
                forecastPeriodOne.isDayTime
              )}
              period={forecastPeriodOne}
            ></ForecastPeriodCard>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ForecastPeriodCard
              faIcon={getIconForText(
                forecastPeriodTwo.shortForecast,
                forecastPeriodTwo.isDayTime
              )}
              period={forecastPeriodTwo}
            ></ForecastPeriodCard>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
