import { gql } from "@apollo/client";

export const CURRENT_CONDITIONS_BY_ZIPCODE = gql
`query currentConditionsForZip($zipCode: String!) {
  currentConditionsForZip(zipCode: $zipCode) {
    timestamp
    textDescription
    temperature {
      value
    }
    dewpoint {
      value
    }
    relativeHumidity {
      value
    }      
    windSpeed {
      value
    }
    heatIndex {
      value
    }
    relativeLocation {
      properties {
        city
        state
      }
    }
  }
  }
`;
