import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

export default function SevereWeather() {
    return (
        <>
            <Card elevation={3}>
                <CardHeader title={<i className="fa-duotone fa-solid fa-tornado fa-2xl" />} />
                <CardContent>
                    <Typography variant="body2" fontSize={18}>Coming soon!  Please visit NOAA's <a href="https://www.spc.noaa.gov/">Storm Prediction Center</a> for up to date information on severe weather outlooks!</Typography>
                </CardContent>
            </Card>
        </>
    )

}