import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, alpha } from "@mui/material/styles";
import { IconButton, InputBase, Tooltip } from "@mui/material";
import { useZipCode } from "./context/zipcode-context";
import TemporaryDrawer from "./SideBar";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, .5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function TopBar() {
  const location = useLocation();
  const history = useHistory();
  const { updateZipCode } = useZipCode();
  const [zipError, setZipError] = React.useState(false)
  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <IconButton
          size="medium"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <TemporaryDrawer />
        </IconButton>

        &ensp;&ensp;
        <Typography onClick={() => {
          history.push("/")
        }}
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { xs: "none", sm: "inline" } }}
        >
          Weather...... Simplified&ensp;&ensp;
          <i className="fa-duotone fa-cloud-rain" />{" "}
          <i className="fa-duotone fa-cloud-sun-rain" />{" "}
          <i className="fa-duotone fa-rainbow" />
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, display: { xs: "none", sm: "inline" } }}
        ></Typography>

        {zipError &&
          <Typography variant="body2" color="#FF0000">Invalid ZIP Code</Typography>
        }
        {!location.pathname.includes("tropics") && !location.pathname.includes("severe-weather") &&
          <Tooltip title="Enter a 5 digit ZIP Code">
            <Search>
              <SearchIconWrapper>
                <i className="fa-duotone fa-magnifying-glass"></i>
              </SearchIconWrapper>
              <StyledInputBase
                type="number"
                onChange={(e) => {
                  if (e.target.value.length > 5) {
                    setZipError(true)
                  } else {
                    setZipError(false)
                    updateZipCode(e.target.value)
                  }
                }}
                placeholder="ZIP Code"
                inputProps={{ "aria-label": "search", maxLength: "5" }}

              />

            </Search>
          </Tooltip>
        }
      </Toolbar>
    </AppBar>

  );
}
