import { useQuery } from "@apollo/client";
import {
  Alert,
  AlertTitle,
  Stack,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { CURRENT_ALERTS_FOR_ZIPCODE } from "./graphql/queries";
import { useZipCode } from "../shared/context/zipcode-context";
import { format, parseISO } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export default function WeatherAlerts() {
  const { state } = useZipCode();
  const [alerts, setAlerts] = useState([]);
  const [alertsLoaded, setAlertsLoaded] = useState(false);
  const [expand, setExpand] = useState([]);

  const { loading: alertDataLoading, data: alertData } = useQuery(
    CURRENT_ALERTS_FOR_ZIPCODE,
    {
      fetchPolicy: "no-cache",
      variables: {
        zipCode: state.zipCode,
      },
      skip: state.zipCode.length < 5,
    }
  );

  const getAlertType = (alert) => {
    if (alert.event.includes("Watch")) {
      return "warning";
    }
    if (alert.event.includes("Warning")) {
      return "error";
    }
    return "warning";
  };

  const getAlertIcon = (alert) => {
    if (alert.event.includes("Watch")) {
      return <i className="fa-duotone fa-solid fa-diamond-exclamation" />;
    }
    if (alert.event.includes("Warning")) {
      return <i className="fa-duotone fa-solid fa-hexagon-exclamation" />;
    }
    return <i className="fa-duotone fa-solid fa-diamond-exclamation" />
  };

  useEffect(() => {
    if (alertData) {
      setAlerts(alertData.currentAlertsForZipCode.features);
      const alertsInitialExpansion = [];
      alertData.currentAlertsForZipCode.features.forEach((alert, i) => {
        alertsInitialExpansion.push({ index: i, expanded: false })
      })
      setExpand(alertsInitialExpansion)
      setAlertsLoaded(true);
    }
  }, [alertDataLoading, alertData]);

  const setExpanded = (i) => {
    const alert = expand.find(alert => {
      return alert.index === i
    })
    const filter = expand.filter(alert => {
      return alert.index !== i
    })

    alert.expanded = !alert.expanded
    filter.push(alert)
    const updatedAlerts = filter.sort((a, b) => {
      if (a.index < b.index)
        return -1;
      if (a.index > b.index)
        return 1;
      return 0;
    })
    setExpand(updatedAlerts);
  }

  return !alertsLoaded ? null : (
    <Stack sx={{ width: "100%" }} spacing={0.25}>
      {alerts.map((alert, i) => (
        <Alert
          key={i}
          icon={getAlertIcon(alert)}
          severity={getAlertType(alert)}
          onClick={() => {
            setExpanded(i)
          }}
          action={
            !expand[i].expanded ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleUp} />
          }
        >
          <AlertTitle>
            <strong>
              {alert.event} in effect until{" "}
              {format(parseISO(alert.expires), "h:mm a")}
            </strong>
          </AlertTitle>
          {expand[i].expanded && <Typography>{alert.description}</Typography>}
        </Alert>
      ))}
    </Stack>
  );
}
