import { gql } from "@apollo/client";

const FORECAST_DAY_PERIOD_FIELDS = `
    name
    isDayTime
    temperature
    probabilityOfPrecipitation
    dewPoint
    relativeHumidity
    windSpeedAndDirection
    shortForecast
    detailedForecast

`;

export const RELATIVE_LOCATION_BY_ZIPCODE = gql
    `query relativeLocationForZip($zipCode: String!) {
  currentConditionsForZip(zipCode: $zipCode) {
    relativeLocation {
      properties {
        city
        state
      }
    }
  }
  }
`;

export const FORECAST_EXTENDED_FOR_ZIPCODE = gql`
  query extendedForecastForZip($zipCode: String!) {
    extendedForecastForZip(zipCode: $zipCode) {
      generatedAt
      periodOne {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodTwo {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodThree {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodFour {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodFive {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodSix {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodSeven {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
      periodEight {
        ${FORECAST_DAY_PERIOD_FIELDS}
      }
    }
  }
`;
