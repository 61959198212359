import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React from "react";

export default function CurrentConditionCard({ faIcon, faIconColor, textDescription, needDegrees, relativeHumidity, heatIndex, unit }) {
  return (
    <Card elevation={3}>
      <br />
      {!relativeHumidity && !heatIndex &&
          <br />
        }
      <CardHeader title={<i className={faIcon} color={faIconColor} />} />
      <CardContent>

        <Typography variant="body2" fontSize={18}>{textDescription ? textDescription : "Current Conditions Not Available"}{needDegrees && <span>&deg;</span>}{unit && <span>{unit}</span>}</Typography>
        {relativeHumidity &&
          <Typography variant="body5" fontSize={10}><strong>Humidity:</strong> {relativeHumidity}%</Typography>
          
        }
         <br />
        {heatIndex &&
       
          <Typography variant="body5" fontSize={10}><strong> Feels Like:</strong> {Math.round(heatIndex)}&deg;</Typography>
        }


      </CardContent>
    </Card>
  );
}
