import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useZipCode } from "../shared/context/zipcode-context";
import { useQuery } from "@apollo/client";
import { FORECAST_EXTENDED_FOR_ZIPCODE, RELATIVE_LOCATION_BY_ZIPCODE } from "./graphql/queries";
import { format, parseISO } from "date-fns";
import ExtendedForecastCard from "./ExtendedForecastCard";

export default function ExtendedForecast() {
    const { state, updateZipCode } = useZipCode();
    const [forecastExtendedLoaded, setForecastExtendedLoaded] = useState(false);
    const [forecastTime, setForecastTime] = useState("");
    const [forecastPeriodOne, setForecastPeriodOne] = useState({});
    const [forecastPeriodTwo, setForecastPeriodTwo] = useState({});
    const [forecastPeriodThree, setForecastPeriodThree] = useState({});
    const [forecastPeriodFour, setForecastPeriodFour] = useState({});
    const [forecastPeriodFive, setForecastPeriodFive] = useState({});
    const [forecastPeriodSix, setForecastPeriodSix] = useState({});
    const [forecastPeriodSeven, setForecastPeriodSeven] = useState({});
    const [forecastPeriodEight, setForecastPeriodEight] = useState({});
    const [relativeLocation, setRelativeLocation] = useState({});
    const {
        loading: relativeLocationLoading,
        data: relativeLocationData,
        refetch: relativeLocationRefetch,
    } = useQuery(RELATIVE_LOCATION_BY_ZIPCODE, {
        pollInterval: 900000,
        variables: {
            zipCode: state.zipCode,
        },
        skip: state.zipCode.length < 5,
    });

    const {
        loading: forecastExtendedLoading,
        data: forecastExtendedData,
        refetch: forecastRefetch,
    } = useQuery(FORECAST_EXTENDED_FOR_ZIPCODE, {
        pollInterval: 900000,
        variables: {
            zipCode: state.zipCode,
        },
        skip: state.zipCode.length < 5,
    });

    useEffect(() => {
        const storedZip = localStorage.getItem("zipCode");
        if (storedZip) {
            updateZipCode(storedZip);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (forecastExtendedData && relativeLocationData) {
            setRelativeLocation(
                relativeLocationData.currentConditionsForZip.relativeLocation
            );
            setForecastTime(forecastExtendedData.extendedForecastForZip.generatedAt);
            setForecastPeriodOne(forecastExtendedData.extendedForecastForZip.periodOne);
            setForecastPeriodTwo(forecastExtendedData.extendedForecastForZip.periodTwo);
            setForecastPeriodThree(forecastExtendedData.extendedForecastForZip.periodThree);
            setForecastPeriodFour(forecastExtendedData.extendedForecastForZip.periodFour);
            setForecastPeriodFive(forecastExtendedData.extendedForecastForZip.periodFive);
            setForecastPeriodSix(forecastExtendedData.extendedForecastForZip.periodSix);
            setForecastPeriodSeven(forecastExtendedData.extendedForecastForZip.periodSeven);
            setForecastPeriodEight(forecastExtendedData.extendedForecastForZip.periodEight);
            setForecastExtendedLoaded(true);
        }
    }, [
        forecastExtendedLoading,
        relativeLocationLoading,
        forecastExtendedData,
        relativeLocationData,
    ]);

    useEffect(() => {
        if (state.zipCode.length === 5) {
            setForecastExtendedLoaded(false);
            relativeLocationRefetch();
            forecastRefetch();
        }
    }, [state.zipCode, forecastRefetch, relativeLocationRefetch]);

    const getIconForText = (forecastText, isDayTime) => {
        if (
            forecastText.includes("Thunderstorm") ||
            forecastText.includes("Thunder Showers")
        ) {
            return "fa-duotone fa-cloud-bolt";
        }
        if (forecastText.includes("Fog") || forecastText.includes("Mist")) {
            return "fa-duotone fa-cloud-fog";
        }
        if (forecastText.includes("Rain") && forecastText.includes("Snow")) {
            return "fa-duotone fa-snowflake-droplets";
        }
        if (forecastText.includes("Rain")) {
            return "fa-duotone fa-cloud-rain";
        }
        if (forecastText.includes("Drizzle")) {
            return "fa-duotone fa-cloud-drizzle fa-2xl";
        }
        if (
            forecastText.includes("Snow") ||
            forecastText.includes("Snow Showers") ||
            forecastText.includes("Flurries")
        ) {
            return "fa-duotone fa-snowflakes fa-2xl";
        }
        if (
            forecastText.includes("Mostly Sunny") ||
            forecastText.includes("Partly Cloudy")
        ) {
            return !isDayTime
                ? "fa-duotone fa-clouds-moon"
                : "fa-duotone fa-clouds-sun";
        }
        if (forecastText.includes("Sunny") || forecastText.includes("Clear")) {
            return !isDayTime ? "fa-duotone fa-moon-stars" : "fa-duotone fa-sun";
        }
        if (forecastText.includes("Cloudy")) {
            return "fa-duotone fa-clouds";
        }
        if (
            forecastText.includes("Tropical Storm") ||
            forecastText.includes("Hurricane")
        ) {
            return "fa-duotone fa-solid fa-hurricane";
        }
    };

    return !forecastExtendedLoaded ? <>
        <Box
            sx={{ flexGrow: 1, pt: 5 }}
            margin={4}
            boxShadow={2}
        >
            <CircularProgress />
        </Box></> : (
        <>
            {!forecastExtendedLoaded &&
                <Container>
                    <CircularProgress />
                </Container>
            }
            {forecastExtendedLoaded &&
                <Container>
                    <Typography paddingTop={2} paddingBottom={2} variant="h5">
                        Extended Forecast for {relativeLocation.properties.city},{" "}
                        {relativeLocation.properties.state}
                    </Typography>
                    <Typography paddingBottom={2} variant="subtitle2">
                        generated at {format(parseISO(forecastTime), "h:mm a")}
                    </Typography>
                    <Grid container spacing={1} paddingBottom={5}>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodOne.shortForecast,
                                    forecastPeriodOne.isDayTime
                                )}
                                period={forecastPeriodOne}
                            ></ExtendedForecastCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodTwo.shortForecast,
                                    forecastPeriodTwo.isDayTime
                                )}
                                period={forecastPeriodTwo}
                            ></ExtendedForecastCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodThree.shortForecast,
                                    forecastPeriodThree.isDayTime
                                )}
                                period={forecastPeriodThree}
                            ></ExtendedForecastCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodFour.shortForecast,
                                    forecastPeriodFour.isDayTime
                                )}
                                period={forecastPeriodFour}
                            ></ExtendedForecastCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodFive.shortForecast,
                                    forecastPeriodFive.isDayTime
                                )}
                                period={forecastPeriodFive}
                            ></ExtendedForecastCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodSix.shortForecast,
                                    forecastPeriodSix.isDayTime
                                )}
                                period={forecastPeriodSix}
                            ></ExtendedForecastCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodSeven.shortForecast,
                                    forecastPeriodSeven.isDayTime
                                )}
                                period={forecastPeriodSeven}
                            ></ExtendedForecastCard>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ExtendedForecastCard
                                faIcon={getIconForText(
                                    forecastPeriodEight.shortForecast,
                                    forecastPeriodEight.isDayTime
                                )}
                                period={forecastPeriodEight}
                            ></ExtendedForecastCard>
                        </Grid>
                    </Grid>
                </Container>
            }
        </>
    )
}



