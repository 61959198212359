import { Box, Card, CardContent, CardHeader, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CURRENT_TROPICAL_TWO_PRODUCTS } from "./graphql/queries";
import { useQuery } from "@apollo/client";
import { format, parseISO } from "date-fns";

export default function TropicsDetail() {
    const [tropicsTwoProducts, setTropicsTwoProducts] = useState({})
    const [atlanticDisturbances, setAtlanticDisturbances] = useState([]);
    const [pacificDisturbances, setPacificDisturbances] = useState([]);
    const [tropicsTwoProductsLoaded, setTropicsTwoProductsLoaded] = useState(false);

    const { loading: tropicsTwoProductsLoading, data: tropicsTwoProductsData } = useQuery(
        CURRENT_TROPICAL_TWO_PRODUCTS,
        {
            pollInterval: 900000,
        }
    );

    useEffect(() => {
        if (tropicsTwoProductsData) {
            setTropicsTwoProducts(tropicsTwoProductsData.currentTropicalTwoProduct);
            setAtlanticDisturbances(tropicsTwoProductsData.currentTropicalTwoProduct[0].productText.disturbances)
            setPacificDisturbances(tropicsTwoProductsData.currentTropicalTwoProduct[1].productText.disturbances)
            setTropicsTwoProductsLoaded(true);
        }
    }, [tropicsTwoProductsLoading, tropicsTwoProductsData]);


    const getChanceColor = (description, percentage) => {
        if (percentage === "0 percent" || percentage === "near 0") {
            return "#2ecc71"
        }
        if (description === "low") {
            return "#FFBF00 "
        }
        if (description === "medium") {
            return "#f39c12"
        }
        if (description === "high") {
            return "#c0392b"
        }
    }

    return !tropicsTwoProductsLoaded ? <>
        <Box
            sx={{ flexGrow: 1, pt: 5 }}
            margin={4}
            boxShadow={2}
        >
            <CircularProgress />
        </Box></> : (
        <>
            <Typography paddingTop={4} paddingBottom={2} variant="h5">
                <i className="fa-sharp-duotone fa-solid fa-hurricane" />  Current Tropical Outlook <i className="fa-sharp-duotone fa-solid fa-hurricane" />
            </Typography>
            <br />
            <Container>
                <Grid container spacing={4} paddingBottom={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card elevation={3}>
                            <CardHeader title="Atlantic Ocean" color="textSecondary" />
                            <Typography paddingBottom={5} variant="subtitle2">
                                generated at {format(parseISO(tropicsTwoProducts[0].issuanceTime), "h:mm a")}
                            </Typography>
                            <CardContent align="left">
                                {atlanticDisturbances.length === 0 &&
                                    <>
                                        <Typography variant="body5"> Tropical cyclone formation is not expected during the next 7 days. <br /><br /> </Typography>
                                    </>
                                }
                                {atlanticDisturbances.length > 0 &&
                                    <>
                                        {atlanticDisturbances.map((disturbance, i) => (
                                            <>
                                                <Typography variant="body5"><strong>Disturbance {i + 1}</strong><br /></Typography>
                                                <Typography variant="body5">Location: {disturbance.location}<br /></Typography>
                                                <Typography variant="body5">Development Chance Next 48 Hours: <span style={{ color: getChanceColor(disturbance.chance48hours, disturbance.percent48hours) }}><strong>{disturbance.percent48hours}%</strong></span><br /></Typography>
                                                <Typography variant="body5">Development Chance Next 7 Days: <span style={{ color: getChanceColor(disturbance.chance7days, disturbance.percent7days) }}><strong>{disturbance.percent7days}%</strong></span><br /></Typography>
                                                <br />
                                            </>
                                        ))}
                                    </>
                                }
                                {tropicsTwoProducts[0].productText.weatherOutlook &&
                                    <>
                                        <Typography variant="body5"><br /> <strong>Official Text: </strong></Typography>
                                        <Typography variant="body3">{tropicsTwoProducts[0].productText.weatherOutlook}<br /><br /></Typography>
                                        <Typography variant="body3"><strong>{tropicsTwoProducts[0].productText.forecaster}</strong></Typography>
                                    </>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card elevation={3}>
                            <CardHeader title="Pacific Ocean" color="textSecondary" />
                            <Typography paddingBottom={5} variant="subtitle2">
                                generated at {format(parseISO(tropicsTwoProducts[1].issuanceTime), "h:mm a")}
                            </Typography>
                            <CardContent align="left">
                                {pacificDisturbances.length === 0 &&
                                    <>
                                        <Typography variant="body5"> Tropical cyclone formation is not expected during the next 7 days. <br /><br /> </Typography>
                                    </>
                                }
                                {pacificDisturbances.length > 0 &&
                                    <>
                                        <Typography variant="h5"> Current Disturbances <br /><br /> </Typography>
                                        {pacificDisturbances.map((disturbance, i) => (
                                            <>
                                                <Typography variant="body5"><strong>Disturbance {i + 1}</strong><br /></Typography>
                                                <Typography variant="body5">Location: {disturbance.location}<br /></Typography>
                                                <Typography variant="body5">Development Chance Next 48 Hours: <span style={{ color: getChanceColor(disturbance.chance48hours, disturbance.percent48hours) }}><strong>{disturbance.percent48hours}%</strong></span><br /></Typography>
                                                <Typography variant="body5">Development Chance Next 7 Days: <span style={{ color: getChanceColor(disturbance.chance7days, disturbance.percent7days) }}><strong>{disturbance.percent7days}%</strong></span><br /></Typography>
                                                <br />
                                            </>
                                        ))}
                                    </>
                                }
                                {tropicsTwoProducts[1].productText.weatherOutlook &&
                                    <>
                                        <Typography variant="body5"><br /> <strong>Official Text: </strong></Typography>
                                        <Typography variant="body3">{tropicsTwoProducts[1].productText.weatherOutlook}<br /><br /></Typography>
                                        <Typography variant="body3"><strong>{tropicsTwoProducts[1].productText.forecaster}</strong></Typography>
                                    </>
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    )

}