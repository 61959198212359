import { gql } from "@apollo/client";

export const CURRENT_ALERTS_FOR_ZIPCODE = gql`
  query currentAlertsForZipCode($zipCode: String!) {
    currentAlertsForZipCode(zipCode: $zipCode) {
      county
      features {
        event
        effective
        expires
        severity
        description
      }
    }
  }
`;
