import React from "react";
import { actionTypes } from "./action-types";
import { initialState } from "./initial-state";
import { zipCodeReducer } from "./zipcode-reducer";

const ZipCodeContext = React.createContext(initialState);

function useZipCode() {
  const context = React.useContext(ZipCodeContext);

  if (!context) {
    throw new Error("useZipCode must be used with ZipCodeProvider");
  }

  const [state, dispatch] = context;

  const updateZipCode = (data) =>
    dispatch({ type: actionTypes.updateZipCode, data });

  return {
    state,
    dispatch,
    updateZipCode,
  };
}

function ZipCodeProvider(props) {
  const [zipCode, setZipCode] = React.useReducer(zipCodeReducer, initialState);
  const value = React.useMemo(() => [zipCode, setZipCode], [zipCode]);
  return <ZipCodeContext.Provider value={value} {...props} />;
}

export { ZipCodeProvider, useZipCode };
