import { gql } from "@apollo/client";

export const CURRENT_TROPICAL_ADVISORY = gql`
  query currentTropicalProduct {
    currentTropicalProduct {
     issuingOffice
     issuanceTime
     productText
  }
}
`;


export const CURRENT_TROPICAL_TWO_PRODUCTS = gql`
  query currentTropicalTwoProduct {
  currentTropicalTwoProduct {
  issuanceTime
    issuingOffice
    productText {
      forecaster
      weatherOutlook
      disturbances {
     location
        chance48hours
        percent48hours
        chance7days
        percent7days
      }
    
    }
  }
}
`;