import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { StyledEngineProvider } from "@mui/material";
import { ZipCodeProvider } from "./shared/context/zipcode-context";

const client = new ApolloClient({
  uri: "https://weather-simplified-graphql.azurewebsites.net/graphql",
  cache: new InMemoryCache(),
  connectToDevTools: true
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ZipCodeProvider>
    <StyledEngineProvider injectFirst>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </StyledEngineProvider>
    </ZipCodeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
