import { useQuery } from "@apollo/client";
import { CURRENT_CONDITIONS_BY_ZIPCODE } from "./graphql/queries";
import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useZipCode } from "../shared/context/zipcode-context";
import CurrentConditionCard from "./CurrentConditionCard";

export default function CurrentConditions() {
  const { state, updateZipCode } = useZipCode();
  const [pageLoading, setPageLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [currentConditions, setCurrentConditions] = useState({});
  const [currentConditionsLoaded, setCurrentConditionsLoaded] = useState(false);
  const { data, loading, refetch, error } = useQuery(
    CURRENT_CONDITIONS_BY_ZIPCODE,
    {
      fetchPolicy: "no-cache",
      variables: {
        zipCode: state.zipCode,
      },
      skip: state.zipCode.length < 5,
    }
  );

  useEffect(() => {
    if (error) {
      setPageLoading(false);
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      // We had an error but now data came back
      if (showError) {
        setShowError(false);
      }
      setPageLoading(false);
      setCurrentConditions(data.currentConditionsForZip);
      setCurrentConditionsLoaded(true);
    }
  }, [data, loading, showError]);

  useEffect(() => {
    if (state.zipCode.length === 5) {
      localStorage.setItem("zipCode", state.zipCode);
      setPageLoading(true);
      setCurrentConditionsLoaded(false);
      refetch();
    }
  }, [state.zipCode, refetch]);

  useEffect(() => {
    const storedZip = localStorage.getItem("zipCode");
    if (storedZip) {
      updateZipCode(storedZip);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIconForText = (forecastText) => {
    if (
      forecastText.includes("Thunderstorm") ||
      forecastText.includes("Thunder Showers")
    ) {
      return "fa-duotone fa-cloud-bolt fa-2xl";
    }
    if (forecastText.includes("Fog") || forecastText.includes("Mist")) {
      return "fa-duotone fa-cloud-fog fa-2xl";
    }
    if (forecastText.includes("Rain") && forecastText.includes("Snow")) {
      return "fa-duotone fa-snowflake-droplets fa-2xl";
    }
    if (forecastText.includes("Rain")) {
      return "fa-duotone fa-cloud-rain fa-2xl";
    }
    if (forecastText.includes("Drizzle")) {
      return "fa-duotone fa-cloud-drizzle fa-2xl";
    }
    if (
      forecastText.includes("Snow") ||
      forecastText.includes("Snow Showers") ||
      forecastText.includes("Flurries")
    ) {
      return "fa-duotone fa-snowflakes fa-2xl";
    }
    if (
      forecastText.includes("Mostly Sunny") ||
      forecastText.includes("Partly Cloudy")
    ) {
      return new Date().getHours() > 18 || new Date().getHours() < 6
        ? "fa-duotone fa-clouds-moon fa-2xl"
        : "fa-duotone fa-clouds-sun fa-2xl";
    }
    if (forecastText.includes("Sunny") || forecastText.includes("Clear")) {
      return new Date().getHours() > 18 || new Date().getHours() < 6
        ? "fa-duotone fa-moon-stars fa-2xl"
        : "fa-duotone fa-sun fa-2xl";
    }
    if (forecastText.includes("Cloudy")) {
      return "fa-duotone fa-clouds fa-2xl";
    }
    if (forecastText === "" || !forecastText) {
      return "fa-duotone fa-solid fa-triangle-exclamation fa-2xl";
    }

  };

  const getTempIcon = (temp) => {
    if (temp < 32) {
      return "fa-duotone fa-temperature-quarter fa-2xl";
    }
    if (temp < 50) {
      return "fa-duotone fa-temperature-half fa-2xl";
    }
    if (temp <= 70) {
      return "fa-duotone fa-temperature-three-quarters fa-2xl";
    }
    if (temp > 70) {
      return "fa-duotone fa-temperature-full fa-2xl";
    }
  };

  const getTempIconColor = (temp) => {
    if (temp < 45) {
      return "#0000FF";
    }
    if (temp < 65 && temp >= 45) {
      return "";
    }
    if (temp < 85 && temp >= 70) {
      return "#FF5733";
    }
    if (temp >= 85) {
      return "#C70039";
    }
  };

  return !currentConditionsLoaded ? (
    <>
      <Box
        sx={{ flexGrow: 1, pt: 5 }}
        margin={4}
        boxShadow={2}
      >
        {pageLoading && <CircularProgress />}
        {!pageLoading && (
          <>
            <Typography paddingBottom={4} variant="h5">
              {showError && (
                <span>
                  No location exists for the ZIP code you entered......
                </span>
              )}
              {!showError && (
                <span>Enter a ZIP Code for your weather...... simplified</span>
              )}
            </Typography>
            <Typography paddingBottom={4} variant="h6">
              <i className="fa-duotone fa-cloud-rain" />{" "}
              <i className="fa-duotone fa-cloud-sun-rain" />{" "}
              <i className="fa-duotone fa-rainbow" />
            </Typography>
          </>
        )}
      </Box>
    </>
  ) : (
    <Container>
      <Typography paddingTop={4} paddingBottom={2} variant="h5">
        Currently for {currentConditions.relativeLocation.properties.city},{" "}
        {currentConditions.relativeLocation.properties.state}
      </Typography>
      <Typography paddingBottom={2} variant="subtitle2">
        {format(parseISO(currentConditions.timestamp), "MMMMMMM dd yyyy")}{" "}
        observed at {format(parseISO(currentConditions.timestamp), "h:mm a")}
      </Typography>
      <Grid container spacing={1} paddingBottom={2}>
        <Grid item xs={4} sm={4} md={4}>
          <CurrentConditionCard
            faIcon={getIconForText(currentConditions.textDescription)}
            textDescription={`${currentConditions.textDescription}`}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <CurrentConditionCard
            unit={"F"}
            needDegrees={true}
            relativeHumidity={Math.round(currentConditions.relativeHumidity.value)}
            heatIndex={(currentConditions.heatIndex.value * 9) / 5 + 32}
            faIcon={getTempIcon(
              (currentConditions.temperature.value * 9) / 5 + 32
            )}
            faIconColor={getTempIconColor(
              (currentConditions.temperature.value * 9) / 5 + 32
            )}
            textDescription={`${Math.round(
              (currentConditions.temperature.value * 9) / 5 + 32
            )}`}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <CurrentConditionCard
            faIcon={"fa-duotone fa-windsock fa-2xl"}
            textDescription={`${Math.round(
              currentConditions.windSpeed.value / 1.609344
            )} mph`}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
