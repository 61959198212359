import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function TemporaryDrawer() {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        history.push("/")
                    }}>
                        <ListItemIcon>
                            <i className="fa-sharp-duotone fa-solid fa-house fa-2xl"></i>
                        </ListItemIcon>
                        <ListItemText primary={'Current Conditions'}></ListItemText>
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        history.push("/extended")
                    }}>
                        <ListItemIcon>
                            <i className="fa-sharp-duotone fa-solid fa-calendar fa-2xl"></i>
                        </ListItemIcon>
                        <ListItemText primary={'Extended Forecast'}></ListItemText>
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        history.push("/severe-weather")
                    }}>
                        <ListItemIcon>
                            <i className="fa-sharp-duotone fa-solid fa-tornado fa-2xl"></i>
                        </ListItemIcon>
                        <ListItemText primary={'Severe Weather'}></ListItemText>
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        history.push("/tropics")
                    }}>
                        <ListItemIcon>
                            <i className="fa-sharp-duotone fa-solid fa-hurricane fa-2xl"></i>
                        </ListItemIcon>
                        <ListItemText primary={'Tropics'}></ListItemText>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            <Typography onClick={toggleDrawer(true)}
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { sm: "inline" } }}
            ><i className='fa-duotone fa-solid fa-bars'></i></Typography>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}