import { actionTypes } from "./action-types";

export function zipCodeReducer(state, action) {
  const reducer = {
    [actionTypes.updateZipCode]: updateZipCode,
  }[action.type];

  if (!reducer) {
    throw new Error(`${action.type} is not supported by zipCodeReducer`);
  }
  return reducer(state, action);
}

const updateZipCode = (state, action) => {
  const { data } = action;
  return {
    ...state,
    zipCode: data,
  };
};
