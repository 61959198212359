
import { BottomNavigation, BottomNavigationAction, Box, Typography } from '@mui/material';
import './App.css';
import CurrentConditions from './currentConditions/CurrentConditions';
import TopBar from './shared/TopBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faPatreon } from '@fortawesome/free-brands-svg-icons';
import WeatherAlerts from './weatherAlerts/WeatherAlerts';
import ForecastDay from './forecastDay/ForecastDay';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ExtendedForecast from './extendedForecast/ExtendedForecast';
import TropicsDetail from './tropics/TropicsDetail';
import SevereWeather from './severeWeather/severeWeather';

function App() {

  const createdBy = '{ created with a love of weather by keith }'
  return (
    <Router>
      <header className="App-header">
        <TopBar></TopBar>
      </header>
      <WeatherAlerts />
      <div className="App">
        <Switch>
          <Route exact path="/">
            <CurrentConditions></CurrentConditions>
            <ForecastDay></ForecastDay>
          </Route>
          <Route path="/extended">
            <ExtendedForecast></ExtendedForecast>
          </Route>
          <Route path="/tropics">
            <TropicsDetail></TropicsDetail>
          </Route>
          <Route path="/severe-weather">
            <SevereWeather></SevereWeather>
          </Route>
        </Switch>


        <Box marginTop={25}>
          <BottomNavigation showLabels={true} style={{ 'backgroundColor': '#F2F2F2' }}>
            <BottomNavigationAction label="Support This Site" target="_tab" href="https://www.patreon.com/keithlietzau" icon={<FontAwesomeIcon size="2xl" icon={faPatreon} />} />
            <BottomNavigationAction label="Github" target="_tab" href="https://github.com/SmellzLikeYeti" icon={<FontAwesomeIcon size="2xl" icon={faGithub} />} />
          </BottomNavigation>


        </Box>

        <Typography variant='caption' color="textSecondary">{createdBy}</Typography>
      </div>
    </Router>
  );
}

export default App;
